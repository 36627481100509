<template>
    <div class="text-primary rounded-lg overflow-x-auto">
        <table class="min-w-full bg-surface rounded-md shadow-md">
            <thead>
                <tr class="bg-secondary text-surface">
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Name
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Source Sites
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Onions
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        CSEA
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="site in sites" :key="site.id" class="hover:bg-black hover:bg-opacity-10">
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        {{ site.name }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[150px]">
                        {{ site.source_site }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[200px]"
                        @mouseover="showTooltip(site.onions, $event)" @mousemove="updateTooltipPosition"
                        @mouseleave="hideTooltip" @click="openOnionModal(site.onions)">
                        <span v-for="(onion, index) in site.onions" :key="index"
                            class="inline-block mr-1 px-2 py-1 bg-secondary bg-opacity-20 rounded-full text-xs text-surface truncate max-w-[70px]"
                            style="vertical-align: middle;">
                            {{ onion }}
                        </span>

                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate">
                        <span
                            :class="site.is_csea ? 'bg-accent-light text-surface' : 'bg-secondary bg-opacity-20 text-surface'"
                            class="px-2 py-1 inline-flex text-xs font-semibold rounded-full">
                            {{ site.is_csea ? 'Yes' : 'No' }}
                        </span>
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border text-sm font-medium truncate">
                        <button @click="editSite(site)" class="text-accent hover:text-accent-dark mr-2 truncate">
                            Edit
                        </button>
                        <button @click="hideSite(site)" class="text-secondary hover:text-secondary-dark truncate">
                            Hide
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <SiteEdit v-if="showEditModal" :show="showEditModal" :site="currentEditSite"
            @update:show="showEditModal = $event" @confirm="handleEditConfirm" />
        <OnionToolTip :show="tooltipVisible" :x="tooltipX" :y="tooltipY" :onions="currentOnions" />
        <OnionModal v-if="showOnionModal" :show="showOnionModal" :onions="currentOnions"
            @update:show="showOnionModal = $event" />
    </div>

</template>

<script setup>
import { defineEmits, ref } from 'vue';
import SiteEdit from '@/components/site/SiteEdit.vue';
import OnionToolTip from '@/components/shared/OnionToolTip.vue';
import OnionModal from '@/components/shared/OnionModal.vue';
import { useApi } from '@/composables/api';

const api = useApi();



defineProps({
    sites: {
        type: Array,
        required: true
    }
});

const emit = defineEmits(['edit-site', 'delete-site', 'refresh-sites', 'confirm']);

const showEditModal = ref(false);
const currentEditSite = ref(null);

// New refs for tooltip functionality
const tooltipVisible = ref(false);
const tooltipX = ref(0);
const tooltipY = ref(0);
const currentOnions = ref([]);

// Add this to your existing refs
const showOnionModal = ref(false);


// Add this function to your existing functions
function openOnionModal(onions) {
    currentOnions.value = onions;
    showOnionModal.value = true;
}

// New functions for tooltip functionality
function showTooltip(onions, event) {
    currentOnions.value = onions;
    tooltipVisible.value = true;
    updateTooltipPosition(event);
}

function hideTooltip() {
    tooltipVisible.value = false;
}

function updateTooltipPosition(event) {
    tooltipX.value = event.clientX + 10; // Offset to prevent tooltip from appearing under cursor
    tooltipY.value = event.clientY + 10;
}

function editSite(site) {
    currentEditSite.value = { ...site };
    showEditModal.value = true;
}

function hideSite(site) {
    try {
        const res = api.hideSite(site.uuid);
        if (res) {
            emit('refresh-sites');
        }
    } catch (err) {
        console.error('Failed to hide site:', err);
    }
}

function handleEditConfirm(editedSite) {
    emit('edit-site', editedSite);
    showEditModal.value = false;
    emit('confirm', editedSite);
    emit('refresh-sites');
}
</script>