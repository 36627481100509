<template>
    <div v-if="show" @click="closeOnOutsideClick"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-surface rounded-lg p-6 max-w-full m-32 w-full" @click.stop>
            <h2 class="text-xl font-bold mb-4">Onion Addresses</h2>
            <ul class="space-y-2">
                <li v-for="(onion, index) in onions" :key="index" class="flex items-center">
                    <span class="mr-2 flex-grow truncate">{{ onion }}.onion</span>
                    <button @click="copyOnion(onion)"
                        class="px-3 py-1 bg-accent text-surface rounded hover:bg-accent-dark transition-colors">
                        {{ copied === onion ? 'Copied!' : 'Copy' }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const props = defineProps({
    show: Boolean,
    onions: Array,
});

const emit = defineEmits(['update:show']);

const copied = ref('');

function copyOnion(onion) {
    navigator.clipboard.writeText(onion + ".onion").then(() => {
        copied.value = onion;
        setTimeout(() => {
            copied.value = '';
        }, 2000);
    });
}

function closeOnOutsideClick(event) {
    if (event.target === event.currentTarget) {
        emit('update:show', false);
    }
}
</script>