<template>
    <div @click="toggleExpanded"
        class="bg-surface text-primary rounded-lg shadow-md p-4 mb-4 hover:shadow-lg hover:bg-opacity-5 transition-shadow duration-300">
        <div class="flex justify-between items-center cursor-pointer">
            <h2 class="text-xl font-semibold">{{ site._source.manualSiteName || 'Unnamed Site' }}</h2>
            <div class="flex items-center">
                <span class="mr-4" :class="site._source.isCSEA ? 'text-accent-dark' : 'text-accent-light'">
                    {{ site._source.isCSEA ? 'CSEA' : 'Not CSEA' }}
                </span>
                <svg class="w-6 h-6 transition-transform duration-200" :class="{ 'transform rotate-180': expanded }"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
        </div>

        <div v-if="!expanded" class="mt-2">
            <h3 class="text-sm font-medium mb-1">Onion Addresses:</h3>
            <ul class="list-disc list-inside">
                <li v-for="(onion, index) in site._source.onions.slice(0, 2)" :key="index" class="text-sm break-all">
                    {{ onion }}
                </li>
                <li v-if="site._source.onions.length > 2" class="text-sm">
                    ...and {{ site._source.onions.length - 2 }} more
                </li>
            </ul>
        </div>

        <div v-if="expanded" class="mt-4">
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <h3 class="text-lg font-semibold mb-2">General Information</h3>
                    <div class="mb-2 p-4 rounded-lg bg-background">
                        <p><strong>Last Up Test:</strong> {{ formatDate(site._source.lastUpTest) }}</p>
                        <p><strong>Is Up:</strong> {{ site._source.isUp ? 'Yes' : 'No' }}</p>
                        <p><strong>First Seen:</strong> {{ formatDate(site._source.firstSeen) }}</p>
                        <p><strong>Hidden:</strong> {{ site._source.hidden ? 'Yes' : 'No' }}</p>
                    </div>
                </div>

                <div>
                    <h3 class="text-lg font-semibold mb-2">Categories</h3>
                    <ul v-if="site._source.category.length"
                        class="list-disc list-inside mb-2 p-4 rounded-lg bg-background">
                        <li v-for="(category, index) in site._source.category" :key="index">
                            {{ category || 'Uncategorized' }}
                        </li>
                    </ul>
                    <p v-else>No categories specified</p>
                </div>
            </div>

            <div class="mt-4">
                <h3 class="text-lg font-semibold mb-2">Onion Addresses</h3>
                <ul class="list-none mb-2 p-4 rounded-lg bg-background">
                    <li v-for="(onion, index) in site._source.onions" :key="index"
                        class="break-all flex justify-between items-center mb-2">
                        <div class="flex-grow flex items-center cursor-pointer" @click.stop="copyOnion(onion)">
                            <button
                                class="mr-2 bg-accent hover:bg-accent-light text-white font-bold py-1 px-2 rounded-lg text-xs focus:outline-none focus:shadow-outline transition duration-200">
                                {{ copied === onion ? 'Copied!' : 'Copy' }}
                            </button>
                            <span>{{ onion }}</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="mt-4">
                <h3 class="text-lg font-semibold mb-2">Onion Statuses</h3>
                <div v-for="(status, index) in site._source.onionStatuses" :key="index"
                    class="mb-2 p-4 bg-background rounded-lg">
                    <p><strong>Address:</strong> {{ status.address }}</p>
                    <p><strong>Status:</strong> {{ status.isUp ? 'Up' : 'Down' }}</p>
                    <p><strong>Last Checked:</strong> {{ formatDate(status.lastChecked) }}</p>
                    <p><strong>Reason:</strong> {{ status.reason }}</p>
                </div>
            </div>

            <div class="mt-4">
                <h3 class="text-lg font-semibold mb-2">Sources</h3>
                <ul class="list-disc list-inside mb-2 p-4 rounded-lg bg-background">
                    <li v-for="(source, index) in site._source.sources" :key="index">
                        {{ source }}
                    </li>
                </ul>
            </div>

            <div class="mt-6 flex justify-end space-x-4">
                <button @click.stop="openEditModal"
                    class="bg-accent hover:bg-accent-light text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline transition duration-200">
                    Edit Site
                </button>
                <button @click.stop="showDeleteModal = true"
                    class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline transition duration-200">
                    Delete Site
                </button>
            </div>
        </div>
    </div>

    <!-- Site Delete Confirmation Modal -->
    <div v-if="showDeleteModal"
        class="fixed inset-0 bg-black text-primary bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-surface text-main-text p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 class="text-xl font-semibold mb-4">Confirm Site Deletion</h3>
            <p class="mb-6">
                Are you sure you want to delete the site "{{ site._source.manualSiteName || 'Unnamed Site' }}"?
                This action cannot be undone.
            </p>
            <div class="flex justify-end space-x-4">
                <button @click="showDeleteModal = false"
                    class="px-4 py-2 bg-accent hover:bg-accent-light text-main-text rounded transition duration-200">
                    Cancel
                </button>
                <button @click="confirmDelete"
                    class="px-4 py-2 bg-red-500 hover:bg-accent text-main-text rounded transition duration-200">
                    Delete
                </button>
            </div>
        </div>
    </div>

    <!-- Site Delete Confirmation Modal -->
    <div v-if="showConfirmModal"
        class="fixed inset-0 bg-black text-primary bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-surface text-main-text p-6 rounded-lg shadow-xl max-w-md w-full">
            <h3 class="text-xl font-semibold mb-4">Confirm Site Updates</h3>
            <p class="mb-6">
                Are you sure you want to update "{{ site._source.manualSiteName || 'Unnamed Site' }}"?
                This will alter the site's information in elastic.
            </p>
            <div class="flex justify-end space-x-4">
                <button @click="showConfirmModal = false"
                    class="px-4 py-2 bg-accent hover:bg-accent-light text-main-text rounded transition duration-200">
                    Cancel
                </button>
                <button @click="confirmEdit"
                    class="px-4 py-2 bg-green-500 hover:bg-accent text-main-text rounded transition duration-200">
                    Confirm
                </button>
            </div>
        </div>
    </div>

    <!-- Edit Modal -->
    <PopularSiteEditModal v-if="showEditModal" :site="site" @close="showEditModal = false" @update="handleSiteUpdate" />
</template>

<script setup>
import { ref } from 'vue';
import PopularSiteEditModal from '@/components/popularsites/PopularSiteEditModal.vue';

const emit = defineEmits(['delete', 'updateSite']);

const props = defineProps({
    site: {
        type: Object,
        required: true
    },
});

const expanded = ref(false);
const showDeleteModal = ref(false);
const showEditModal = ref(false);
const showConfirmModal = ref(false);
const updateSite = ref('');
const copied = ref('');

function toggleExpanded() {
    expanded.value = !expanded.value;
}

function formatDate(dateString) {
    return new Date(dateString).toLocaleString();
}

function confirmDelete() {
    emit('delete', props.site);
    showDeleteModal.value = false;
}

function openEditModal() {
    showEditModal.value = true;
}

function handleSiteUpdate(updatedSite) {
    updateSite.value = updatedSite;
    showEditModal.value = false;
    showConfirmModal.value = true;
}

function confirmEdit() {
    emit('updateSite', updateSite.value);
    showConfirmModal.value = false;
}

function copyOnion(onion) {
    navigator.clipboard.writeText(onion + ".onion").then(() => {
        copied.value = onion;
        setTimeout(() => {
            copied.value = '';
        }, 2000);
    });
}
</script>