<template>
    <div class="bg-background min-h-screen">
        <h1 class="text-3xl font-bold mb-6 text-primary">Sites Management</h1>
        <div class="mb-6 flex justify-between items-center text-primary">

            <div class="flex items-center">
                <input type="checkbox" id="csea-toggle" v-model="showCseaSites" @change="toggleCseaMode"
                    class="mr-2 ml-2">
                <label for="csea-toggle">View CSEA Sites</label>
            </div>
        </div>
        <!-- Add the filter input -->
        <input v-model="filterText" type="text" placeholder="Filter by site name"
            class="w-full p-2 mb-4 bg-surface text-primary rounded">

        <PopularSite v-for="site in filteredSites" :key="site._id" :site="site" @delete="handleDelete"
            @updateSite="updateSite" />

        <!-- Password Modal -->
        <div v-if="showPasswordModal" class="fixed inset-0 bg-primary bg-opacity-50 flex items-center justify-center">
            <div class="bg-surface p-6 rounded-lg">
                <h2 class="text-xl font-bold mb-4 text-main-text">Enter CSEA Password</h2>
                <input type="password" v-model="cseaPassword"
                    class="border p-2 mb-4 w-full bg-background text-main-text">
                <div class="flex justify-end">
                    <button @click="cancelCseaMode"
                        class="bg-accent text-main-text px-4 py-2 rounded mr-2">Cancel</button>
                    <button @click="confirmCseaMode" class="bg-primary text-nav-text px-4 py-2 rounded">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useSitesStore } from '@/stores/sites';
import { useApi } from '@/composables/api';
import PopularSite from '@/components/popularsites/PopularSite.vue';

const api = useApi();

const showCseaSites = ref(false);
const showPasswordModal = ref(false);
const cseaPassword = ref('');
const filterText = ref('');

const CSEA_PASSWORD = import.meta.env.VITE_CSEA_PASSWORD; // Replace with your actual CSEA password

const sitesStore = useSitesStore();
onMounted(async () => {
    if (!sitesStore.sites.length) {
        await sitesStore.fetchSites();
    }
});

const filteredSites = computed(() => {
    return sitesStore.sites.filter(site =>
        site._source.manualSiteName.toLowerCase().includes(filterText.value.toLowerCase())
    );
});

const toggleCseaMode = () => {
    if (showCseaSites.value) {
        showPasswordModal.value = true;
    } else {
        sitesStore.setCseaMode(false);
        sitesStore.fetchSites();
    }
};

const confirmCseaMode = () => {
    if (cseaPassword.value === CSEA_PASSWORD) {
        showPasswordModal.value = false;
        sitesStore.setCseaMode(true);
        sitesStore.fetchSites();
    } else {
        alert('Incorrect password');
        showCseaSites.value = false;
    }
    cseaPassword.value = '';
};

const cancelCseaMode = () => {
    showPasswordModal.value = false;
    showCseaSites.value = false;
    cseaPassword.value = '';
    sitesStore.setCseaMode(false);
    sitesStore.fetchSites();
};

function handleDelete(siteToDelete) {
    console.log('Deleting site:', siteToDelete);
    api.deleteFromEs(siteToDelete._id);
    sitesStore.sites = sitesStore.sites.filter(site => site !== siteToDelete);
}

function handleDeleteOnion(siteId, onionToDelete) {
    api.deleteOnionFromEs(onionToDelete, siteId);
    sitesStore.sites = sitesStore.sites.map(site => {
        if (site._id === siteId) {
            site._source.onions = site._source.onions.filter(onion => onion !== onionToDelete);
        }
        return site;
    });
    console.log('Deleting onion:', onionToDelete, siteId);
}

function updateSite(updatedSite) {
    api.updatePopularSite(updatedSite);
    sitesStore.sites = sitesStore.sites.map(site => {
        if (site._id === updatedSite.site_uuid) {
            site._source.onions = updatedSite.onions;
            site._source.sources = updatedSite.sources;
            site._source.isCSEA = updatedSite.isCsea;
        }
        return site;
    });
    // TODO add functionality to send api request to backend to update single elastic doc
    console.log(updatedSite);
}
</script>
