<template>
    <div v-if="show"
        class="fixed inset-0 bg-black bg-opacity-30 overflow-y-auto h-full w-full flex items-center justify-center text-text">
        <div class="bg-surface p-8 rounded-lg shadow-xl w-full max-w-2xl">
            <h2 class="text-2xl font-bold mb-4 text-primary">Edit Site</h2>
            <div class="mb-4 px-2 py-1 border-border rounded">
                If "Site Name" field matches an existing site from elastic (the sites in the dropdown below), that
                elastic entry will be updated.
            </div>
            <div class="mb-4">
                <label class="block mb-2 text-text">Site Name</label>
                <input v-model="editedSite.name" type="text"
                    class="border border-border rounded px-2 py-1 w-full bg-background text-text">
            </div>

            <div class="mb-4">
                <label class="block mb-2 text-text">Merge with Existing Site Name</label>
                <select v-model="selectedExistingSite"
                    class="border border-border rounded px-2 py-1 w-full bg-background text-text">
                    <option value="">Keep as new site
                    </option>
                    <option v-for="siteName in siteNames" :key="siteName" :value="siteName">
                        {{ siteName }}
                    </option>
                </select>
            </div>

            <div class="mb-4">
                <label class="block mb-2 text-text">Source Site</label>
                <input v-model="editedSite.source_site" type="text"
                    class="border border-border rounded px-2 py-1 w-full bg-background text-text">
            </div>

            <div class="mb-4">
                <label class="block mb-2 text-text">Onions</label>
                <div v-for="(onion, index) in editedSite.onions" :key="index" class="flex items-center mb-2">
                    <input v-model="onion.value" type="text" :class="['border border-border rounded px-2 py-1 w-full mr-2 bg-background text-text',
        { 'opacity-50': onion.deleted }]" :disabled="onion.deleted">
                    <button @click="toggleOnionStatus(index)" :class="['hover:text-accent-dark',
        onion.deleted ? 'text-accent' : 'text-red-500']">
                        {{ onion.deleted ? 'Undo' : 'Remove' }}
                    </button>
                </div>
                <button @click="addOnion" class="text-accent hover:text-accent-dark">Add Onion</button>
            </div>

            <div class="mb-4 flex items-center">
                <input v-model="editedSite.is_csea" type="checkbox" class="mr-2">
                <label class="text-text">
                    Is CSEA
                </label>
            </div>

            <div class="flex justify-end">
                <button @click="cancel"
                    class="bg-secondary hover:bg-secondary text-surface font-bold py-2 px-4 rounded mr-2">
                    Cancel
                </button>
                <button @click="confirm"
                    class="bg-accent hover:bg-accent-dark text-surface font-bold py-2 px-4 rounded">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useSitesStore } from '@/stores/sites';
import { useApi } from '@/composables/api';

const api = useApi();

const props = defineProps({
    show: Boolean,
    site: Object,
});

const emit = defineEmits(['update:show', 'confirm', 'site-updated']);

const sitesStore = useSitesStore();
const editedSite = ref({});
const selectedExistingSite = ref('');
const isLoading = ref(false);
const error = ref(null);

const siteNames = sitesStore.siteNames;

watch(() => props.site, (newSite) => {
    if (newSite) {
        editedSite.value = {
            ...newSite,
            onions: newSite.onions.map(onion => ({ value: onion, deleted: false }))
        };
    }
}, { immediate: true });

watch(selectedExistingSite, (newValue) => {
    if (newValue) {
        editedSite.value.name = newValue;
    }
});

function addOnion() {
    if (!editedSite.value.onions) {
        editedSite.value.onions = [];
    }
    editedSite.value.onions.push({ value: '', deleted: false });
}

function toggleOnionStatus(index) {
    editedSite.value.onions[index].deleted = !editedSite.value.onions[index].deleted;
}

function cancel() {
    emit('update:show', false);
}

async function confirm() {
    isLoading.value = true;
    error.value = null;

    try {
        // Filter out deleted onions and extract only the values
        const updatedOnions = editedSite.value.onions
            .filter(onion => !onion.deleted)
            .map(onion => onion.value);

        const siteToUpdate = {
            ...editedSite.value,
            onions: updatedOnions
        };

        const updatedSite = await api.uploadSiteUpdate(siteToUpdate);
        emit('confirm', updatedSite);
    } catch (err) {
        console.error('Error updating site:', err);
        error.value = 'Failed to update site. Please try again.';
    } finally {
        isLoading.value = false;
    }
}
</script>