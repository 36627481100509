<template>
    <nav
        class="bg-primary h-screen w-16 fixed left-0 top-0 flex flex-col transition-all duration-300 ease-in-out hover:w-64 rounded-r-2xl overflow-hidden group">
        <div class="flex-shrink-0 flex items-center justify-center h-16 text-surface overflow-hidden">
            <span
                class="text-lg font-bold whitespace-nowrap transition-all duration-200 group-hover:opacity-0 group-hover:w-0">
                PSM
            </span>
            <span
                class="text-lg font-bold whitespace-nowrap transition-all duration-200 opacity-0 w-0 group-hover:opacity-100 group-hover:w-auto">
                Popular Sites Manager
            </span>
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
            <div class="flex-1 space-y-1">
                <router-link v-for="link in userLinks" :key="link.name" :to="link.path"
                    class="text-surface hover:bg-accent flex items-center px-4 py-4 text-sm font-medium relative">
                    <div
                        class="w-8 flex items-center justify-center absolute left-4 group-hover:left-4 transition-all duration-300">
                        <SvgIcon :name="link.imgpath" fill="currentColor" stroke="currentColor" stroke-width="1.5"
                            class="h-6 w-6 text-main-text" />
                    </div>
                    <span class="truncate ml-16 opacity-0 group-hover:opacity-100 transition-opacity duration-300">{{
                    link.name }}</span>
                </router-link>


                <div @click="toggleTheme"
                    class="text-surface hover:bg-accent flex items-center px-4 py-4 text-sm font-medium relative">
                    <div
                        class="w-8 flex items-center justify-center absolute left-4 group-hover:left-4 transition-all duration-300">
                        <SvgIcon name="/svgs/right.svg" fill="currentColor" stroke="currentColor" stroke-width="1.5"
                            class="h-6 w-6 text-main-text" />
                    </div>
                    <span
                        class="truncate ml-16 opacity-0 group-hover:opacity-100 transition-opacity duration-300">Toggle
                        Theme </span>
                </div>

                <!-- Add more nav items as needed -->
            </div>
        </div>
        <div class="flex-shrink-0 flex border-t border-surface">
            <button @click="logout"
                class="text-surface hover:bg-accent flex items-center text-sm font-medium w-full relative p-4">
                <div
                    class="w-8 flex items-center justify-center absolute left-4 group-hover:left-4 transition-all duration-300">
                    <svg class="h-6 w-6 text-surface" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                </div>
                <span
                    class="truncate ml-16 opacity-0 group-hover:opacity-100 transition-opacity duration-300">Logout</span>
            </button>
        </div>
    </nav>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { useApi } from '@/composables/api';
import { ref, onMounted, watch } from 'vue';
import SvgIcon from '@/components/utils/SvgIcon.vue';

const api = useApi();
const router = useRouter();
const auth = useAuthStore();

const userLinks = [
    { name: 'Incoming Sites', path: '/sites', imgpath: '/svgs/right.svg' },
    { name: 'Incoming Site Audit', path: '/audit', imgpath: '/svgs/audit.svg' },
    { name: 'Leaderboard', path: '/leaderboard', imgpath: '/svgs/leaderboard.svg' },
    { name: 'Popular Sites', path: '/popularsites', imgpath: '/svgs/popularsites.svg' },
];

const username = ref('User');

onMounted(async () => {
    try {
        const userData = await api.getUser();
        username.value = userData.username || 'User';
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
});

const logout = () => {
    auth.logout();
    router.push('/login');
};

const isDarkMode = ref(false);

const toggleTheme = () => {
    auth.theme = !auth.theme;
    auth.applyTheme();
    // isDarkMode.value = !isDarkMode.value;
};

// watch(auth.theme, (newValue) => {
//     if (newValue) {
//         document.body.classList.add('dark-theme');
//     } else {
//         document.body.classList.remove('dark-theme');
//     }
// }, { immediate: true });
</script>