import { defineStore } from "pinia";
import axios from "axios";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    username: null,
    theme: true,
  }),
  actions: {
    async login(username, password) {
      try {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        const response = await axios.post("/auth/token", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        this.accessToken = response.data.access_token;
        this.refreshToken = response.data.refresh_token;
        this.isAuthenticated = true;

        return true;
      } catch (error) {
        console.error("Authentication failed:", error);
        return false;
      }
    },
    async register(email, username, password, api_key) {
      try {
        const data = {
          email: email,
          username: username,
          password: password,
          auth_key: api_key,
        };

        await axios.post("/auth/register", data);

        return true;
      } catch (error) {
        console.error("Registration failed:", error);
        return false;
      }
    },
    logout() {
      this.isAuthenticated = false;
      this.accessToken = null;
      this.refreshToken = null;
    },
    setAccessToken(token) {
      this.accessToken = token;
      this.isAuthenticated = true;
    },

    setTheme(isDark) {
      this.theme = isDark;
      this.applyTheme();
    },
    applyTheme() {
      if (this.theme) {
        document.body.classList.add("dark-theme");
        document.body.classList.remove("light-theme");
      } else {
        document.body.classList.add("light-theme");
        document.body.classList.remove("dark-theme");
      }
    },
    initTheme() {
      // Call this method when your app initializes
      this.applyTheme();
    },
  },
  persist: true,
});
