<template>
    <div class="bg-background min-h-screen">
        <h1 class="text-3xl font-bold text-primary pb-3">Incoming Sites</h1>
        <div class="mb-6 flex justify-between items-center">

            <div class="flex items-center">
                <input type="checkbox" id="hidden-toggle" v-model="showHiddenSites" @change="toggleHiddenMode"
                    class="mr-2 ml-4">
                <label for="hidden-toggle" class="text-text">View Hidden Sites</label>
                <input type="checkbox" id="csea-toggle" v-model="showCseaSites" @change="toggleCseaMode"
                    class="mr-2 ml-4">
                <label for="csea-toggle" class="text-text">View CSEA Sites</label>
            </div>
            <button @click="openSiteEditModal('create')"
                class="bg-accent hover:bg-accent-dark text-surface font-bold py-2 px-4 rounded">
                Create New Site
            </button>
        </div>

        <!-- Add the filter input -->
        <input v-model="filterText" type="text" placeholder="Filter sites by name"
            class="w-full p-2 mb-4 bg-surface text-text border border-border rounded">

        <SiteList :sites="filteredSites" @edit-site="openSiteEditModal('edit', $event)" @delete-site="handleDeleteSite"
            @refresh-sites="fetchSites" @confirm="handleSiteEditConfirm" />

        <!-- Password Modal -->
        <div v-if="showPasswordModal" class="fixed inset-0 bg-primary bg-opacity-50 flex items-center justify-center">
            <div class="bg-surface p-6 rounded-lg shadow-md">
                <h2 class="text-xl font-bold mb-4 text-primary">Enter CSEA Password</h2>
                <input type="password" v-model="cseaPassword"
                    class="border border-border p-2 mb-4 w-full text-text bg-surface">
                <div class="flex justify-end">
                    <button @click="cancelCseaMode"
                        class="bg-secondary text-surface px-4 py-2 rounded mr-2">Cancel</button>
                    <button @click="confirmCseaMode" class="bg-accent text-surface px-4 py-2 rounded">Confirm</button>
                </div>
            </div>
        </div>

        <!-- SiteEdit Modal -->
        <SiteEdit v-if="showSiteEditModal" :show="showSiteEditModal" :site="currentEditSite"
            @update:show="showSiteEditModal = $event" @confirm="handleSiteEditConfirm"
            @cancel="showSiteEditModal = false" />
    </div>
</template>



<script setup>
import { ref, onMounted, computed } from 'vue';
import { useApi } from '@/composables/api';
import { useSitesStore } from '@/stores/sites';
import SiteList from '@/components/site/SiteList.vue';
import SiteEdit from '@/components/site/SiteEdit.vue';

const { getSites, getCseaSites, getHiddenSites, getHiddenCseaSites, deleteSite } = useApi();
const sitesStore = useSitesStore();

const sites = ref([]);
const showSiteEditModal = ref(false);
const currentEditSite = ref(null);
const showCseaSites = ref(false);
const showHiddenSites = ref(false);
const showPasswordModal = ref(false);
const cseaPassword = ref('');
const filterText = ref('');

const CSEA_PASSWORD = import.meta.env.VITE_CSEA_PASSWORD; // Replace with your actual CSEA password

onMounted(async () => {
    await fetchSites();
    await sitesStore.fetchSites();
});

const fetchSites = async () => {
    try {
        if (showHiddenSites.value && showCseaSites.value) {
            sites.value = await getHiddenCseaSites();
        } else if (showHiddenSites.value) {
            sites.value = await getHiddenSites();
        } else if (showCseaSites.value) {
            sites.value = await getCseaSites();
        } else {
            sites.value = await getSites();
        }
    } catch (error) {
        console.error('Failed to fetch sites:', error);
        // TODO: Add error handling, e.g., show an error message to the user
    }
};

const filteredSites = computed(() => {
    return sites.value.filter(site =>
        site.name.toLowerCase().includes(filterText.value.toLowerCase())
    );
});

const toggleCseaMode = () => {
    if (showCseaSites.value) {
        showPasswordModal.value = true;
    } else {
        sitesStore.setCseaMode(false);
        fetchSites();
    }
};

const toggleHiddenMode = () => {
    fetchSites();
};

const confirmCseaMode = () => {
    if (cseaPassword.value === CSEA_PASSWORD) {
        showPasswordModal.value = false;
        sitesStore.setCseaMode(true);
        fetchSites();
    } else {
        alert('Incorrect password');
        showCseaSites.value = false;
    }
    cseaPassword.value = '';
};

const cancelCseaMode = () => {
    showPasswordModal.value = false;
    showCseaSites.value = false;
    cseaPassword.value = '';
    sitesStore.setCseaMode(false);
};

const openSiteEditModal = (mode, site = null) => {
    currentEditSite.value = mode === 'create'
        ? { name: '', source_site: '', onions: [''], is_csea: false }
        : { ...site };
    showSiteEditModal.value = true;
};

const handleSiteEditConfirm = async (editedSite) => {
    if (editedSite.id) {
        // Update existing site
        const index = sites.value.findIndex(site => site.id === editedSite.id);
        if (index !== -1) {
            sites.value[index] = editedSite;
        }
    } else {
        // Add new site
        sites.value.push(editedSite);
    }

    showSiteEditModal.value = false;

    await fetchSites(); // Refresh the list to ensure consistency with the server
};

const handleDeleteSite = async (siteId) => {
    try {
        await deleteSite(siteId);
        sites.value = sites.value.filter(site => site.id !== siteId);
    } catch (error) {
        console.error('Failed to delete site:', error);
        // TODO: Add error handling
    }
};
</script>
