import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";

import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import LoginView from "@/views/LoginView.vue";
import SitesView from "@/views/SitesView.vue";
import AuditView from "@/views/AuditView.vue";
import LeaderboardView from "@/views/LeaderboardView.vue";
import PopularSitesView from "@/views/PopularSitesView.vue";
import RegisterView from "@/views/RegisterView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/sites" },
    {
      path: "/login",
      name: "Login",
      component: LoginView,
      meta: { isPublic: true },
    },
    {
      path: "/register",
      name: "Register",
      component: RegisterView,
      meta: { isPublic: true },
    },
    {
      path: "/sites",
      name: "Sites",
      components: { default: SitesView, layout: AuthenticatedLayout },
      meta: { requiresAuth: true },
    },
    {
      path: "/audit",
      name: "Audit",
      components: { default: AuditView, layout: AuthenticatedLayout },
      meta: { requiresAuth: true },
    },
    {
      path: "/leaderboard",
      name: "Leaderboard",
      components: { default: LeaderboardView, layout: AuthenticatedLayout },
      meta: { requiresAuth: true },
    },
    {
      path: "/popularsites",
      name: "Popular Sites",
      components: { default: PopularSitesView, layout: AuthenticatedLayout },
      meta: { requiresAuth: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();
  // auth.checkAuth();
  if (to.meta.requiresAuth && !auth.isAuthenticated) {
    // Redirect unauthenticated users to the login page
    next({ name: "Login" });
  } else if (to.meta.isPublic && auth.isAuthenticated) {
    // Redirect authenticated users away from login page to dashboard
    next({ name: "Sites" });
  } else {
    next();
  }
});

export default router;
