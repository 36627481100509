// src/axiosConfig.js
// This sets up Axios to use the backend URL as its default baseURL
// also sends access token header off and refreshes the token if it's expired

import axios from "axios";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";

(axios.defaults.baseURL = import.meta.env.VITE_BASE_URL),
  (axios.defaults.withCredentials = true); // Ensure cookies are sent with requests

// Request interceptor to add Authorization header
axios.interceptors.request.use(
  (config) => {
    const auth = useAuthStore();
    if (auth.accessToken) {
      config.headers["Authorization"] = `Bearer ${auth.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const auth = useAuthStore();
    // Check for a 401 error and that it hasn't been retried yet
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/auth/token") &&
      !originalRequest.url.includes("/auth/refresh")
    ) {
      originalRequest._retry = true; // Mark the request as retried
      // Attempt to refresh the token
      try {
        const refreshToken = auth.refreshToken;
        if (!refreshToken) {
          throw new Error("No refresh token available");
        }
        const response = await axios.post("/auth/refresh", {
          refresh_token: refreshToken,
        });
        const newAccessToken = response.data.access_token;
        // Update the access token in your auth store
        auth.setAccessToken(newAccessToken);
        // Update the Authorization header for the original request
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        // Retry the original request with the new access token
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        // If refresh fails, log out the user and redirect to login
        auth.logout();
        router.push({ name: "Login" });
        return Promise.reject(refreshError);
      }
    }
    // For other cases, just propagate the error
    return Promise.reject(error);
  }
);

export default axios;
