<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout.vue';

const authStore = useAuthStore();
authStore.initTheme();
const route = useRoute();
const layout = computed(() => {
  // Only return the layout component if it is explicitly defined in the route's components
  return route.matched.length > 0 && route.matched[0].components.layout
    ? route.matched[0].components.layout
    : 'div'; // Use a plain 'div' as the default layout when none is specified
});
</script>
