import { defineStore } from "pinia";
import axios from "../composables/axiosConfig";

export const useSitesStore = defineStore("sites", {
  state: () => ({
    siteNames: [],
    sites: [],
    isLoading: false,
    error: null,
    isCseaMode: false,
  }),
  actions: {
    async fetchSites() {
      this.isLoading = true;
      this.error = null;
      try {
        const endpoint = this.isCseaMode ? "/es/csea-sites" : "/es/sites";
        const response = await axios.get(endpoint);
        this.siteNames = response.data
          .map((site) => site._source.manualSiteName)
          .sort();
        this.sites = response.data;
      } catch (error) {
        console.error("Error fetching sites:", error);
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    clearSiteNames() {
      this.siteNames = [];
    },
    setCseaMode(isEnabled) {
      this.isCseaMode = isEnabled;
      this.fetchSites(); // Fetch sites immediately when mode changes
    },
  },
  getters: {
    getSiteNames: (state) => state.siteNames,
    getIsLoading: (state) => state.isLoading,
    getError: (state) => state.error,
    getIsCseaMode: (state) => state.isCseaMode,
  },
  persist: {
    paths: ["siteNames", "isCseaMode"],
  },
});
