<template>
    <div class="fixed inset-0 bg-black text-primary bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-surface text-main-text p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <h3 class="text-xl font-semibold mb-4">Edit Site: {{ site._source.manualSiteName || 'Unnamed Site' }}</h3>

            <div class="mb-4">
                <h4 class="text-lg font-semibold mb-2">Onion Addresses</h4>
                <ul class="mb-2 space-y-2">
                    <li v-for="(onion, index) in onions" :key="index" class="flex items-center">
                        <div class="flex-grow relative">
                            <input v-model="onions[index]" class="w-full p-2 text-black border rounded"
                                :class="{ 'border-red-500': !isValidOnion(onion) && showValidationErrors }"
                                :placeholder="`Onion address ${index + 1}`">
                            <span v-if="!isValidOnion(onion) && showValidationErrors"
                                class="text-red-500 text-xs bottom-[-20px] left-0">
                                Onion address must be 56 characters long
                            </span>
                        </div>
                        <button @click="removeOnion(index)"
                            class="ml-2 bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-lg text-xs">
                            Remove
                        </button>
                    </li>
                </ul>
                <button @click="addOnion"
                    class="bg-accent hover:bg-accent-light text-white font-bold py-2 px-4 rounded-lg">
                    Add Onion Address
                </button>
            </div>

            <div class="mb-4">
                <h4 class="text-lg font-semibold mb-2">Sources</h4>
                <ul class="mb-2 space-y-2">
                    <li v-for="(source, index) in sources" :key="index" class="flex items-center">
                        <input v-model="sources[index]" class="flex-grow p-2 text-black border border-primary rounded"
                            :placeholder="`Source ${index + 1}`">
                        <button @click="removeSource(index)"
                            class="ml-2 bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-lg text-xs">
                            Remove
                        </button>
                    </li>
                </ul>
                <button @click="addSource"
                    class="bg-accent hover:bg-accent-light text-white font-bold py-2 px-4 rounded-lg">
                    Add Source
                </button>
            </div>

            <div class="mb-4">
                <div class="space-y-2">
                    <div>
                        <label class="block text-sm font-medium mb-1">Is CSEA</label>
                        <input type="checkbox" v-model="isCSEA" class="mr-2">
                        <span>{{ isCSEA ? 'Yes' : 'No' }}</span>
                    </div>
                </div>
            </div>

            <div v-if="showValidationErrors && !areAllOnionsValid" class="mb-4 text-red-500">
                Please correct the invalid onion addresses before saving.
            </div>

            <div class="flex justify-end space-x-4">
                <button @click="$emit('close')"
                    class="px-4 py-2 bg-accent hover:bg-accent-light text-white rounded transition duration-200">
                    Cancel
                </button>
                <button @click="validateAndSave"
                    class="px-4 py-2 bg-accent-dark hover:bg-accent text-white rounded transition duration-200">
                    Save Changes
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';

const props = defineProps({
    site: {
        type: Object,
        required: true
    }
});

const emit = defineEmits(['close', 'update']);

const onions = ref([]);
const sources = ref([]);
const isCSEA = ref(false);
const showValidationErrors = ref(false);

onMounted(() => {
    onions.value = [...props.site._source.onions];
    sources.value = [...props.site._source.sources];
    isCSEA.value = props.site._source.isCSEA || false;
});

function addOnion() {
    onions.value.push('');
}

function removeOnion(index) {
    onions.value.splice(index, 1);
}

function addSource() {
    sources.value.push('');
}

function removeSource(index) {
    sources.value.splice(index, 1);
}

function isValidOnion(onion) {
    return onion.length === 56;
}

const areAllOnionsValid = computed(() => {
    return onions.value.every(onion => isValidOnion(onion));
});

function validateAndSave() {
    showValidationErrors.value = true;
    if (areAllOnionsValid.value) {
        saveChanges();
    }
}

function saveChanges() {
    const updatedSite = {
        site_uuid: props.site._id,
        onions: onions.value.filter(onion => onion.trim() !== ''),
        sources: sources.value.filter(source => source.trim() !== ''),
        isCsea: isCSEA.value,
    }
    emit('update', updatedSite);
    emit('close');
}
</script>