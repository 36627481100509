import "./assets/main.css";
import "./assets/theme.css";
import "./composables/axiosConfig";

import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

Sentry.init({
  app,
  dsn: "https://2a587124568bb6f00bafac3650186156@sentry.slcyber.io/15",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.5, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia);
app.use(router);

import { useAuthStore } from "@/stores/auth";
const auth = useAuthStore();

// Ensure the auth state is checked before the app mounts
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !auth.isAuthenticated) {
    next({ name: "Login" });
  } else if (to.name === "Login" && auth.isAuthenticated) {
    next({ name: "Home" });
  } else {
    next();
  }
});

app.mount("#app");
