<template>
    <div class="bg-background min-h-screen">
        <h1 class="text-3xl font-bold mb-6 text-primary">Incoming Site Audit</h1>
        <div class="mb-6 flex justify-between items-center text-primary">

            <div class="flex items-center">
                <input type="checkbox" id="csea-toggle" v-model="showCseaSites" @change="toggleCseaMode"
                    class="mr-2 ml-2">
                <label for="csea-toggle">View CSEA Sites</label>
            </div>
        </div>

        <AuditList :sites="sites" />


        <!-- Password Modal -->
        <div v-if="showPasswordModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div class="bg-white p-6 rounded-lg">
                <h2 class="text-xl font-bold mb-4">Enter CSEA Password</h2>
                <input type="password" v-model="cseaPassword" class="border p-2 mb-4 w-full">
                <div class="flex justify-end">
                    <button @click="cancelCseaMode"
                        class="bg-gray-300 text-black px-4 py-2 rounded mr-2">Cancel</button>
                    <button @click="confirmCseaMode" class="bg-blue-500 text-white px-4 py-2 rounded">Confirm</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useApi } from '@/composables/api';
import AuditList from '@/components/audit/AuditList.vue';
import SiteEdit from '@/components/site/SiteEdit.vue';
import { useSitesStore } from '@/stores/sites';

const { getResolvedSites, getResolvedCseaSites } = useApi();
const sitesStore = useSitesStore();

const sites = ref([]);
const showSiteEditModal = ref(false);
const currentEditSite = ref(null);
const showCseaSites = ref(false);
const showHiddenSites = ref(false);
const showPasswordModal = ref(false);
const cseaPassword = ref('');

const CSEA_PASSWORD = import.meta.env.VITE_CSEA_PASSWORD; // Replace with your actual CSEA password

onMounted(async () => {
    await fetchSites();
    await sitesStore.fetchSites();
});

const fetchSites = async () => {
    try {
        if (showCseaSites.value) {
            sites.value = await getResolvedCseaSites();
        } else {
            sites.value = await getResolvedSites();
        }
    } catch (error) {
        console.error('Failed to fetch sites:', error);
        // TODO: Add error handling, e.g., show an error message to the user
    }
};

const toggleCseaMode = () => {
    if (showCseaSites.value) {
        showPasswordModal.value = true;
    } else {
        sitesStore.setCseaMode(false);
        fetchSites();
    }
};

const confirmCseaMode = () => {
    if (cseaPassword.value === CSEA_PASSWORD) {
        showPasswordModal.value = false;
        sitesStore.setCseaMode(true);
        fetchSites();
    } else {
        alert('Incorrect password');
        showCseaSites.value = false;
    }
    cseaPassword.value = '';
};

const cancelCseaMode = () => {
    showPasswordModal.value = false;
    showCseaSites.value = false;
    cseaPassword.value = '';
    sitesStore.setCseaMode(false);
};

</script>

<style scoped>
.page-container {
    min-height: calc(100vh - 64px);
    background-color: #374151;
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    flex: 1;
    padding: 2rem 1rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}
</style>