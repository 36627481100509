<template>
    <div class="text-primary rounded-lg overflow-x-auto">
        <table class="min-w-full bg-surface rounded-md shadow-md">
            <thead>
                <tr class="bg-secondary text-surface">
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Incoming Site Name
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Resolved Site Name
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Source Sites
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Onions
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        CSEA
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        Who Resolved
                    </th>
                    <th
                        class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider truncate">
                        When Resolved
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="site in sites" :key="site.id">
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        {{ site.name }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        {{ site.resolved_sitename }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        {{ site.source_site }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]"
                        @mouseover="showTooltip(site.onions, $event)" @mousemove="updateTooltipPosition"
                        @mouseleave="hideTooltip" @click="openOnionModal(site.onions)">
                        <span v-for="(onion, index) in site.onions" :key="index"
                            class="inline-block mr-1 px-2 py-1 bg-secondary bg-opacity-20 rounded-full text-xs text-surface truncate max-w-[70px]">
                            {{ onion }}
                        </span>
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        <span :class="site.is_csea ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
                            {{ site.is_csea ? 'Yes' : 'No' }}
                        </span>
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        {{ site.who_resolved }}
                    </td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border truncate max-w-[120px]">
                        {{ site.when_resolved }}
                    </td>
                </tr>
            </tbody>
        </table>
        <OnionToolTip :show="tooltipVisible" :x="tooltipX" :y="tooltipY" :onions="currentOnions" />
        <OnionModal v-if="showOnionModal" :show="showOnionModal" :onions="currentOnions"
            @update:show="showOnionModal = $event" />
    </div>
</template>

<script setup>
import { defineEmits, ref } from 'vue';
import SiteEdit from '@/components/site/SiteEdit.vue';
import OnionToolTip from '@/components/shared/OnionToolTip.vue';
import OnionModal from '@/components/shared/OnionModal.vue';
import { useApi } from '@/composables/api';
const api = useApi();


defineProps({
    sites: {
        type: Array,
        required: true
    }
});

// New refs for tooltip functionality
const tooltipVisible = ref(false);
const tooltipX = ref(0);
const tooltipY = ref(0);
const currentOnions = ref([]);

// Add this to your existing refs
const showOnionModal = ref(false);


// Add this function to your existing functions
function openOnionModal(onions) {
    currentOnions.value = onions;
    showOnionModal.value = true;
}

// New functions for tooltip functionality
function showTooltip(onions, event) {
    currentOnions.value = onions;
    tooltipVisible.value = true;
    updateTooltipPosition(event);
}

function hideTooltip() {
    tooltipVisible.value = false;
}

function updateTooltipPosition(event) {
    tooltipX.value = event.clientX + 10; // Offset to prevent tooltip from appearing under cursor
    tooltipY.value = event.clientY + 10;
}

</script>