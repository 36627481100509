<template>
    <span v-html="svgContent"></span>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    fill: {
        type: String,
        default: 'currentColor'
    },
    stroke: {
        type: String,
        default: 'currentColor'
    },
    strokeWidth: {
        type: String,
        default: '2'
    }
});

const svgContent = ref('');

const processSvg = (svg) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, 'image/svg+xml');
    const svgElement = doc.documentElement;

    svgElement.removeAttribute('width');
    svgElement.removeAttribute('height');

    svgElement.querySelectorAll('*').forEach(el => {
        if (el.hasAttribute('fill') && el.getAttribute('fill') !== 'none') {
            el.setAttribute('fill', props.fill);
        }
        if (el.hasAttribute('stroke')) {
            el.setAttribute('stroke', props.stroke);
        }
        if (el.hasAttribute('stroke-width')) {
            el.setAttribute('stroke-width', props.strokeWidth);
        }
    });

    return new XMLSerializer().serializeToString(svgElement);
};

const loadSvg = async () => {
    try {
        const response = await fetch(props.name);
        if (!response.ok) throw new Error('Failed to load SVG');
        const svg = await response.text();
        svgContent.value = processSvg(svg);
    } catch (error) {
        console.error('Error loading SVG:', error);
    }
};

onMounted(loadSvg);

watch(() => props.name, loadSvg);
watch(() => props.fill, loadSvg);
watch(() => props.stroke, loadSvg);
watch(() => props.strokeWidth, loadSvg);
</script>