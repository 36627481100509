<template>

    <h2 class="text-2xl font-bold mb-4 text-primary">
        {{ isCsea ? 'CSEA' : 'Normal' }} Sites Resolver Leaderboard
    </h2>
    <div class="text-primary rounded-lg overflow-x-auto">
        <table class="min-w-full bg-surface rounded-md shadow-md">
            <thead>
                <tr class="bg-secondary text-surface">
                    <th class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider">
                        Resolver
                    </th>
                    <th class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider">
                        Sites
                        Resolved</th>
                    <th class="px-4 py-2 border-b border-border text-left text-xs font-medium uppercase tracking-wider">
                        Resolved
                        Per Day</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="resolver in leaderboardData" :key="resolver.name">
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border">{{ resolver.name }}</td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border">{{ resolver.count }}</td>
                    <td class="px-4 py-2 whitespace-nowrap border-b border-border">{{ resolver.rpd }}</td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    sites: {
        type: Array,
        required: true
    },
    isCsea: {
        type: Boolean,
        default: false
    }
});

const calculateRPD = (count, firstDate, lastDate) => {
    const daysDiff = (lastDate - firstDate) / (1000 * 60 * 60 * 24) + 1;
    return (count / daysDiff).toFixed(2);
};

const leaderboardData = computed(() => {
    const resolverMap = new Map();

    props.sites.forEach(site => {
        if (site.who_resolved) {
            if (!resolverMap.has(site.who_resolved)) {
                resolverMap.set(site.who_resolved, {
                    name: site.who_resolved,
                    count: 0,
                    firstResolved: new Date(site.when_resolved),
                    lastResolved: new Date(site.when_resolved)
                });
            }

            const resolver = resolverMap.get(site.who_resolved);
            resolver.count += 1;
            const resolvedDate = new Date(site.when_resolved);
            if (resolvedDate < resolver.firstResolved) resolver.firstResolved = resolvedDate;
            if (resolvedDate > resolver.lastResolved) resolver.lastResolved = resolvedDate;
        }
    });

    return Array.from(resolverMap.values())
        .map(resolver => ({
            ...resolver,
            rpd: calculateRPD(resolver.count, resolver.firstResolved, resolver.lastResolved)
        }))
        .sort((a, b) => b.count - a.count);
});
</script>