<template>
    <Teleport to="body">
        <div v-if="show" ref="tooltip" class="onion-tooltip text-sm" :style="tooltipStyle">
            <div v-for="(onion, index) in onions" :key="index" class="onion-item">
                {{ onion }}
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    show: Boolean,
    x: Number,
    y: Number,
    onions: Array,
});

const tooltip = ref(null);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);

const updateWindowDimensions = () => {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
};

onMounted(() => {
    window.addEventListener('resize', updateWindowDimensions);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateWindowDimensions);
});

const tooltipStyle = computed(() => {
    if (!tooltip.value) return {};

    const tooltipRect = tooltip.value.getBoundingClientRect();
    let left = props.x;
    let top = props.y;

    // Check if tooltip goes off the right edge
    if (left + tooltipRect.width > windowWidth.value) {
        left = props.x - tooltipRect.width;
    }

    // Check if tooltip goes off the bottom edge
    if (top + tooltipRect.height > windowHeight.value) {
        top = props.y - tooltipRect.height;
    }

    return {
        left: `${left}px`,
        top: `${top}px`,
    };
});
</script>

<style scoped>
.onion-tooltip {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
    overflow-y: auto;
}

.onion-item {
    margin-bottom: 5px;
}
</style>