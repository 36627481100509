<template>
    <div class="bg-primary p-8 rounded-lg shadow-lg max-w-sm mx-auto">
        <form @submit.prevent="handleLogin">
            <div class="mb-6">
                <label for="username" class="block text-surface text-sm font-bold mb-2">
                    Email
                </label>
                <input v-model="email" id="email" type="text"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
                    required autofocus>
            </div>
            <div class="mb-6">
                <label for="username" class="block text-surface text-sm font-bold mb-2">
                    Username
                </label>
                <input v-model="username" id="username" type="text"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
                    required autofocus>
            </div>
            <div class="mb-6">
                <label for="password" class="block text-surface text-sm font-bold mb-2">
                    Password
                </label>
                <input v-model="password" id="password" type="password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-text mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    required>
            </div>
            <div class="mb-6">
                <label for="apikey" class="block text-surface text-sm font-bold mb-2">
                    API Key
                </label>
                <input v-model="apikey" id="apikey" type="password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-text mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    required>
            </div>
            <div class="flex flex-col items-center">
                <button type="submit"
                    class="bg-accent hover:bg-accent-light text-text font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    :disabled="loading">
                    Register
                </button>
                <div v-if="loading" class="mt-2">Logging in...</div>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'

const email = ref('')
const username = ref('')
const password = ref('')
const apikey = ref('')
const auth = useAuthStore()
const router = useRouter()
const loading = ref(false)

const handleLogin = async () => {
    loading.value = true;
    const success = await auth.register(email.value, username.value, password.value, apikey.value);
    // console.log(success)
    loading.value = false;
    if (success) {
        router.push({ name: 'Login' });
    } else {
        alert('Error Registering');
    }
}
</script>

<style scoped>
/* Add form specific styles here */
</style>