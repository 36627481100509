import axios from "./axiosConfig";

export function useApi() {
  // Sites
  const getSites = async (skip = 0, limit = 1000) => {
    try {
      const response = await axios.get("/api/sites", {
        params: { skip, limit },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw error;
    }
  };

  // Sites
  const getCseaSites = async (skip = 0, limit = 1000, csea = true) => {
    try {
      const response = await axios.get("/api/sites", {
        params: { skip, limit, csea },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw error;
    }
  };

  const getResolvedSites = async (skip = 0, limit = 1000, resolved = true) => {
    try {
      const response = await axios.get("/api/sites", {
        params: { skip, limit, resolved },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw error;
    }
  };

  // Sites
  const getResolvedCseaSites = async (
    skip = 0,
    limit = 1000,
    csea = true,
    resolved = true
  ) => {
    try {
      const response = await axios.get("/api/sites", {
        params: { skip, limit, csea, resolved },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw error;
    }
  };
  // Sites
  const getHiddenSites = async (skip = 0, limit = 1000, hidden = true) => {
    try {
      const response = await axios.get("/api/sites", {
        params: { skip, limit, hidden },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw error;
    }
  };

  // Sites
  const getHiddenCseaSites = async (
    skip = 0,
    limit = 1000,
    hidden = true,
    csea = true
  ) => {
    try {
      const response = await axios.get("/api/sites", {
        params: { skip, limit, csea, hidden },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching sites:", error);
      throw error;
    }
  };

  const hideSite = async (siteUuid) => {
    try {
      const response = await axios.post("/api/sites/hide", {
        site_uuid: siteUuid,
      });
      return response.data;
    } catch (error) {
      console.error("Error hiding site:", error);
      throw error;
    }
  };

  const uploadSiteUpdate = async (siteData) => {
    try {
      const response = await axios.post("/api/sites/upload-update", {
        name: siteData.name,
        onions: siteData.onions,
        is_csea: siteData.is_csea,
        source_site: siteData.source_site,
        uuid: siteData.uuid,
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading site update:", error);
      throw error;
    }
  };

  const getUser = async () => {
    try {
      const response = await axios.get("/auth/users/me");
      return response.data;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

  const deleteFromEs = async (siteUuid) => {
    try {
      const response = await axios.post("/es/delete", {
        site_uuid: siteUuid,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting site from ES:", error);
      throw error;
    }
  };

  const deleteOnionFromEs = async (onionToDelete, siteUuid) => {
    try {
      const response = await axios.post("/es/delete-onion", {
        onion: onionToDelete,
        site_uuid: siteUuid,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting Onion from ES:", error);
      throw error;
    }
  };

  const updatePopularSite = async (site) => {
    try {
      const response = await axios.post("/es/update-entry", {
        onions: site.onions,
        site_uuid: site.site_uuid,
        sources: site.sources,
        isCsea: site.isCsea,
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting Onion from ES:", error);
      throw error;
    }
  };

  // Helper function to get the CSRF token from a cookie
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  return {
    getSites,
    getCseaSites,
    getResolvedSites,
    getResolvedCseaSites,
    getHiddenSites,
    getHiddenCseaSites,
    hideSite,
    uploadSiteUpdate,
    getUser,
    deleteFromEs,
    deleteOnionFromEs,
    updatePopularSite,
  };
}
